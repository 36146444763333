<template>
  <div class="legend">
    <div class="legend-column">
      <p><strong>Markers</strong></p>
      <div class="legend-column-item">
        <svg-icon icon="mapMarkerUser" />
        <p>User position</p>
      </div>
      <div class="legend-column-item">
        <svg-icon icon="mapMarkerStart" />
        <p>Route start</p>
      </div>
      <div class="legend-column-item">
        <svg-icon icon="mapMarkerEnd" />
        <p>Route end</p>
      </div>
    </div>
    <div class="legend-column">
      <p><strong>Paths</strong></p>
      <div class="legend-column">
        <div class="legend-column-item">
          <span class="path-to-route"></span>
          <p>Path to route</p>
        </div>
        <div class="legend-column-item">
          <span class="downhill"></span>
          <p>Downhill road</p>
        </div>
        <div class="legend-column-item">
          <span class="climb"></span>
          <p>Climb road</p>
        </div>
        <div class="legend-column-item">
          <span class="smooth"></span>
          <p>Smooth road</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MapLegend"
}
</script>
<style scoped lang="scss">
.legend {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 16px;
  padding: 12px;
  border: 1px solid black;
  border-radius: 10px;

  &-column {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;

    &-item {
      display: flex;
      flex-direction: row;
      grid-gap: 8px;
      align-items: center;

      span {
        width: 40px;
        height: 5px;
        border-radius: 5px;
      }
    }
  }

  p {
    font-size: 11px;
  }
}

.downhill {
  background-color: blue;
}

.path-to-route {
  background-color: #4ea1fe;
}

.smooth {
  background-color: green;
}

.climb {
  background-color: red;
}
</style>
