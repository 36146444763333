<template>
  <main class="routes-page">
    <div class="page-loader" v-if="isLoading">
      <img class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
    </div>
    <MapLeft
      @changeLatitude="changeLatitude"
      @changeLongitude="changeLongitude"
      @panMap="panMap"
      @searchRoutes="searchRoutes"
      @changeRoute="getDetails"
      @setLoading="setLoading"
      :selected-route="selectedRoute"
      :routes="routes"
      :total-count="totalCount"
      @setMarker="setMarker"
    >
      <template slot="latitude">
        <input class="input" v-model="latitude" />
      </template>
      <template slot="longitude">
        <input class="input" v-model="longitude" />
      </template>
    </MapLeft>
    <div class="map">
      <MapLegend />
      <div ref="map" style="height: 100%; width: 100%"></div>
    </div>
  </main>
</template>
<script>
import { getRouteDetails, getRouteGPX, getRoutes } from "@/data"
import to from "await-to-js"
import GpxParser from "gpxparser"
import MapLeft from "@/views/routes/components/MapLeft.vue"
import calculateMapRoutes from "@/mixins/calculateMapRoutes"
import { MAP_DEFAULTS } from "@/views/routes/constants"
import MapLegend from "@/views/routes/components/MapLegend.vue"
import RouteConstructor from "@/views/routes/components/RouteConstructor.vue"
import { mapMutations } from "vuex"

export default {
  name: "Routes",
  components: { MapLegend, MapLeft },
  data() {
    return {
      constructorObject: {
        points: []
      },
      isLoading: true,
      longitude: 0,
      latitude: 0,
      userCustomMarker: null,
      zoom: 14,
      routes: undefined,
      nextPageUrl: "",
      totalCount: 0,
      directionRenderer: null,
      map: {},
      route: {},
      routeMarkers: [],
      selectedRoute: 0,
      routePath: [],
      paths: [],
      parsedGpx: {},
      elevationProfile: {}
    }
  },
  computed: {
    calculateBounds() {
      const digits = 12
      const zoomFactor = Math.pow(1.9, this.zoom)
      const halfWidth = 360 / zoomFactor
      const halfHeight = 180 / zoomFactor

      const north = Number(this.latitude) + halfHeight
      const east = Number(this.longitude) + halfWidth
      const south = Number(this.latitude) - halfHeight
      const west = Number(this.longitude) - halfWidth

      return `${south.toFixed(digits)},${west.toFixed(digits)},${north.toFixed(digits)},${east.toFixed(digits)}`
    }
  },

  mounted() {
    this.map = new window.google.maps.Map(this.$refs.map, MAP_DEFAULTS)
    this.directionsSevice = new window.google.maps.DirectionsService(this.map)
    this.map.addListener("click", this.setUserClickMarker)
    this.isLoading = false
  },
  beforeDestroy() {
    this.map.removeEventListener("click", this.setUserClickMarker)
  },
  methods: {
    setLoading(value) {
      this.isLoading = value
    },
    changeLatitude(value) {
      this.latitude = value
    },
    changeLongitude(value) {
      this.longitude = value
    },
    setUserClickMarker(e) {
      this.userCustomMarker?.setMap(null)
      this.userCustomMarker = this.setMarker({ latLng: e.latLng })
      this.latitude = e.latLng.lat()
      this.longitude = e.latLng.lng()
    },
    async searchRoutes(duration, avgSpeed, filter, nextPageUrl = "") {
      this.isLoading = true
      this.selectedRoute = 0
      if (this.longitude === 0 || this.latitude === 0) {
        alert("Please, set coordinates first, by clicking on map or searching your position")
        this.isLoading = false
        return
      }
      const [err, res] = await to(
        getRoutes({
          bounds: this.calculateBounds,
          duration,
          avgSpeed,
          filter,
          nextPageUrl
        })
      )

      const routes = res.data.routes

      if (routes.length === 0) {
        alert("There are no routes nearby, please, try to reset coordinates")
        this.isLoading = false
        return
      }
      if (err) {
        alert(err.message)
        this.isLoading = false
        return
      }
      this.routes = routes
      this.totalCount = res.data.totalCount
      this.nextPageUrl = res.data.nextPageUrl

      await this.getDetails(this.selectedRoute)
      this.isLoading = false
    },

    async getDetails(index) {
      this.isLoading = true
      this.selectedRoute += index
      if (!this.routes[this.selectedRoute] && index < this.totalCount) {
        const [, res] = await to(
          getRoutes({
            nextPageUrl: this.nextPageUrl
          })
        )

        this.routes = this.routes.concat(res.data.routes)
        this.nextPageUrl = res.data.nextPageUrl
      }
      const { id, type } = this.routes[this.selectedRoute]

      const [err, res] = await to(getRouteDetails(id, type))
      if (err) {
        alert(err.message)
        return
      }
      const trackPoints = res.data[type].track_points
      await this.setMapRoute(trackPoints)
      this.isLoading = false
    },
    clearRoute() {
      this.routePath.forEach(line => {
        line.setMap(null)
      })
      this.routeMarkers.forEach(marker => {
        marker.setMap(null)
      })
      this.routePath = []
      this.routeMarkers = []
    },
    async setMapRoute(trackPoints) {
      this.clearRoute()
      await this.calculatePathToRouteStart(trackPoints[0])
      this.drawRoute(trackPoints)
      const coords = new window.google.maps.LatLng(Number(this.latitude), Number(this.longitude))
      this.map.panTo(coords)
      this.map.setZoom(13)
    },
    drawRoute(points) {
      const startMarker = this.setMarker({
        latLng: new window.google.maps.LatLng(points[0].y, points[0].x),
        icon: {
          path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
          fillColor: "green",
          fillOpacity: 1,
          scale: 1.5
        }
      })
      const endMarker = this.setMarker({
        latLng: new window.google.maps.LatLng(points[points.length - 1].y, points[points.length - 1].x),
        icon: {
          path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
          fillColor: "yellow",
          fillOpacity: 1,
          scale: 1
        }
      })
      this.routeMarkers = [startMarker, endMarker]
      const a = calculateMapRoutes(points)
      a.forEach(segment => {
        segment.forEach(line => {
          const { start, end, color } = line
          const polyline = new window.google.maps.Polyline({
            path: [start, end],
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 6,
            map: this.map
          })
          this.routePath.push(polyline)
        })
      })
    },
    setMarker({ latLng, icon }) {
      let markerOptions = {
        position: latLng,
        map: this.map
      }
      if (icon) {
        markerOptions = { ...markerOptions, icon }
      }
      const marker = new window.google.maps.Marker({
        ...markerOptions
      })
      marker.setMap(this.map)
      return marker
    },
    async calculatePathToRouteStart(point) {
      this.directionRenderer !== null && this.directionRenderer.setMap(null)
      const request = {
        origin: new window.google.maps.LatLng(Number(this.latitude), Number(this.longitude)),
        destination: new window.google.maps.LatLng(Number(point.y), Number(point.x)),
        travelMode: window.google.maps.TravelMode.WALKING // Вы можете заменить на другой режим, такой как WALKING или BICYCLING
      }

      return this.directionsSevice.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.directionRenderer = new window.google.maps.DirectionsRenderer({
            preserveViewport: true,
            suppressMarkers: true
          })
          this.directionRenderer.setMap(this.map)
          this.directionRenderer.setDirections(result)
        } else {
          console.error("Error calculating the route:", status)
        }
      })
    },
    panMap(coords) {
      this.map.panTo(coords)
      this.map.setZoom(13)
    }
  }
}
</script>
<style scoped lang="scss">
.routes-page {
  height: 100vh;
  display: grid;
  grid-template-columns: 30% 70%;
  justify-content: space-between;
  flex-direction: row;
}
.map {
  position: relative;
}
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
</style>
