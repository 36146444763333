<template>
  <div>
    Constructor
    <button :class="['button', 'is-blue']" @click="createGoogle">
      Get google maps route
    </button>
    <button :class="['button', 'is-blue']" @click="clearPoints">Clear Points</button>
  </div>
</template>
<script>
import axios from "axios"

export default {
  name: "RouteConstructor",
  props: {
    constructorObject: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    clearPoints() {
      return ""
    },
    async createGoogle() {
      await axios("https://overpass-api.de/api/interpreter", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: "[out:json];way (around:100000,19.5068094, 51.840541);"
      })
    }
    // async overpassApi() {
    //   await axios("https://overpass-api.de/api/interpreter", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded"
    //     },
    //     body: "[out:json];way (around:100000,19.5068094, 51.840541);"
    //   })
    // }
  }
}
</script>
