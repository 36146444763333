import {
  CLIMB_COLOR,
  CLIMB_COEFFICIENT,
  DOWNHILL_COLOR,
  DOWNHILL_COEFFICIENT,
  SENSITIVITY_INDEX,
  SMOOTH_COLOR
} from "@/views/routes/constants"
import { ma } from "moving-averages"

function calculateColor(elevationDifference) {
  if (elevationDifference < DOWNHILL_COEFFICIENT) {
    return DOWNHILL_COLOR
  } else if (elevationDifference > CLIMB_COEFFICIENT) {
    return CLIMB_COLOR
  } else {
    return SMOOTH_COLOR
  }
}
const calculateMapRoutes = points => {
  let segmentLastIndex = 0

  return points.reduce((acc, currentPoint, i, array) => {
    if (segmentLastIndex > i) {
      return acc
    }
    segmentLastIndex += SENSITIVITY_INDEX - 1

    const currentSegment = array.slice(i, i + SENSITIVITY_INDEX)
    const nextSegmentStartIndex = segmentLastIndex
    const nextSegmentEndIndex = segmentLastIndex + SENSITIVITY_INDEX
    const nextSegment = array.slice(nextSegmentStartIndex, nextSegmentEndIndex)

    let nextAverageElevation
    if (nextSegmentStartIndex >= array.length - 1) {
      //next segment doesn't exist
      nextAverageElevation = points[points.length - 1].e
    } else {
      nextAverageElevation = ma(nextSegment.map(segmentPoint => segmentPoint.e))
    }

    const averageElevation = ma(currentSegment.map(segmentPoint => segmentPoint.e))
    const elevationDifference = nextAverageElevation - averageElevation
    const segmentColor = calculateColor(elevationDifference)
    //create lines
    const linesColored = currentSegment.map((item, lineIndex) => {
      const endLat = currentSegment[lineIndex + 1]?.y
      const endLong = currentSegment[lineIndex + 1]?.x
      let end
      const start = new window.google.maps.LatLng(item.y, item.x)
      end = new window.google.maps.LatLng(endLat, endLong)
      if (!endLat || !endLong) {
        end = new window.google.maps.LatLng(points[segmentLastIndex + 1]?.y, points[segmentLastIndex + 1]?.x)
      }
      return { end, start, color: segmentColor }
    })
    acc.push(linesColored)
    return acc
  }, [])
}

export default calculateMapRoutes
