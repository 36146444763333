<template>
  <aside>
    <div class="form">
      <h1>Let Jack find for you best cycling route nearby</h1>
      <div class="field">
        <p>Latitude:</p>
        <slot name="latitude"> </slot>
      </div>
      <div class="field">
        <p>Longitude:</p>
        <slot name="longitude"> </slot>
      </div>
      <div class="field">
        {{ $t("routes.duration") }}&nbsp;
        <vue-slider
          class="range-slider"
          :value="duration"
          @change="changeDuration"
          :data="durationValues"
          :height="6"
          :tooltip="null"
          :enable-cross="false"
          dotSize="28"
          :railStyle="{ background: '#abd3ff' }"
          :processStyle="{ background: '#2c68de' }"
        >
          <template v-slot:dot="{ value }">
            <div class="custom-tooltip">{{ value }}</div>
          </template>
        </vue-slider>
      </div>
      <div class="field">
        {{ $t("routes.avgSpeed") }}&nbsp;
        <vue-slider
          class="range-slider"
          :value="avgSpeed"
          @change="changeAvgSpeed"
          :data="speedValues"
          :height="6"
          :tooltip="null"
          dotSize="28"
          :railStyle="{ background: '#abd3ff' }"
          :processStyle="{ background: '#2c68de' }"
        >
          <template v-slot:dot="{ value }">
            <div class="custom-tooltip">{{ value }}</div>
          </template>
        </vue-slider>
      </div>

      <div class="route-filters">
        <div>
          <h2>{{ $t("routes.surfaceTitle") }}</h2>
          <div class="input-row" v-for="(surface, index) in surfacesValues" :key="surface.value + index">
            <input
              type="checkbox"
              :id="surface.value + index"
              name="surfaceIndex"
              class="radio-input"
              :value="surface.value"
              v-model="surfaces"
            />

            <label :for="surface.value + index" class="labels">
              {{ surface.text }}
            </label>
          </div>
        </div>
        <div>
          <h2>{{ $t("routes.typeTitle") }}</h2>
          <div class="input-row" v-for="(routeType, index) in routeTypesValues" :key="routeType.value + index">
            <input
              type="checkbox"
              :id="routeType.value + index"
              name="routeTypeIndex"
              class="radio-input"
              :value="routeType.value"
              v-model="routeTypes"
            />

            <label :for="routeType.value + index" class="labels">
              {{ routeType.text }}
            </label>
          </div>
        </div>
      </div>

      <button class="button is-light" @click="findUsersLocation">Find Me</button>
      <button class="button is-blue" @click="searchRoutes">Find The Best Route</button>
    </div>

    <div class="currentRoute" v-if="routesForSelection">
      <h2>Current Route: {{ selectedRouteInfo + 1 }} / {{ totalCount }}</h2>
      <div class="field">
        <p>Name: {{ routesForSelection[selectedRouteInfo].name }}</p>
        <p>Distance: {{ (routesForSelection[selectedRouteInfo].distance / 1000).toFixed(2) }} km</p>
        <p>Duration: {{ ((routesForSelection[selectedRouteInfo].distance / 1000 / avgSpeed) * 60).toFixed(1) }} min</p>
        <p>Surface: {{ surfaceText }} ( unpaved {{ routesForSelection[selectedRouteInfo].unpaved_pct }} %)</p>
        <p>Track type: {{ trackTypeText }}</p>
      </div>
      <div class="field">
        <button :class="['button', 'is-blue', { disabled: selectedRouteInfo === 0 }]" @click="$emit('changeRoute', -1)">
          Previous
        </button>
        <button
          :class="[
            'button',
            'is-blue',
            { disabled: selectedRouteInfo + 1 === routesForSelection.length && selectedRouteInfo + 1 === totalCount }
          ]"
          @click="$emit('changeRoute', 1)"
        >
          Next
        </button>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: "MapLeft",
  props: {
    selectedRoute: {
      type: [Number, undefined],
      default: undefined
    },
    routes: {
      type: [Array],
      default: () => []
    },
    totalCount: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      selectedRouteInfo: this.selectedRoute,
      routesForSelection: null,
      total: 0,
      avgSpeed: 25,
      duration: [15, 60],
      surfacesValues: [
        { text: "Paved", value: "paved" },
        { text: "Mostly Paved", value: "mostly_paved" },
        { text: "Mixed Surfaces", value: "mixed_surfaces" },
        { text: "Mostly Unpaved", value: "mostly_unpaved" },
        { text: "Unpaved", value: "unpaved" }
      ],
      routeTypesValues: [
        { text: "Loop", value: "loop" },
        { text: "Out and Back", value: "out_and_back" },
        { text: "Point-to-Point", value: "point_to_point" }
      ],
      surfaces: [],
      routeTypes: []
    }
  },
  watch: {
    selectedRoute(val) {
      this.selectedRouteInfo = val
    },
    routes(routes) {
      this.routesForSelection = routes
    }
  },
  computed: {
    surfaceText() {
      const surfaceValue = this.routesForSelection[this.selectedRouteInfo].surface
      const surface = this.surfacesValues.find(surface => surfaceValue === surface.value)
      return surface.text
    },
    trackTypeText() {
      const trackTypeValue = this.routesForSelection[this.selectedRouteInfo].track_type
      const tracKType = this.routeTypesValues.find(tracKType => trackTypeValue === tracKType.value)
      return tracKType.text
    },
    durationValues() {
      const step = 5 //minutes
      const maxDuration = 300
      return [...Array.from({ length: maxDuration / step }, (_, i) => i * step + step), maxDuration]
    },
    speedValues() {
      const step = 1 //km/h
      const maxAvgSpeed = 60
      return [...Array.from({ length: maxAvgSpeed / step }, (_, i) => i * step + step), maxAvgSpeed]
    }
  },
  methods: {
    findUsersLocation() {
      this.$emit("setLoading", true)
      navigator.geolocation.getCurrentPosition(
        pos => {
          const coords = new window.google.maps.LatLng(pos.coords.latitude, pos.coords.longitude)

          this.$emit("changeLongitude", coords.lng())
          this.$emit("changeLatitude", coords.lat())
          this.$emit("setMarker", {
            latLng: coords,
            icon: {
              path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
              fillColor: "blue",
              fillOpacity: 1,
              strokeColor: "#000",
              strokeWeight: 2,
              scale: 2
            }
          })
          this.$emit("panMap", coords)
          this.$emit("setLoading", false)
        },
        () => {
          this.$emit("setLoading", false)
        },
        { maximumAge: 0, timeout: 10000, enableHighAccuracy: true }
      )
    },
    changeAvgSpeed(val) {
      this.avgSpeed = val
    },
    changeDuration(val) {
      this.duration = val
    },
    searchRoutes() {
      this.$emit("searchRoutes", this.duration, this.avgSpeed, {
        surfaceValues: this.surfaces,
        routeTypeValues: this.routeTypes
      })
    }
  }
}
</script>
<style scoped lang="scss">
h1 {
  font-size: 24px;
  margin-bottom: 24px;
}
h1 {
  font-size: 18px;
  margin-bottom: 22px;
}
.form {
  padding: 24px;
}
.currentRoute {
  padding: 24px;
  margin-top: auto;
}
.button {
  margin-top: 24px;
}
.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.route-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
